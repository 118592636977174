<template>
  <v-navigation-drawer
    :permanent="!$vuetify.breakpoint.smAndDown"
    app
    clipped
    v-model="showDrawer"
    :width="showDrawer && this.$vuetify.breakpoint.smAndDown ? '100%': '349'"
    style="overflow: hidden;"
  >
    <v-btn icon light class="ml-15 mt-10 d-flex d-md-none" @click="showDrawer = !showDrawer">
      <v-icon>
        mdi-close
      </v-icon>
    </v-btn>
    <v-list-item>
      <v-list-item-content>
        <v-list-item class="title pa-0 ma-0" @click="showDrawer = false">
          <div class="d-flex justify-center align-center" style="position: relative !important">
            <TheAvatarImage
              :profilePhoto="profilePhoto"
              :isPremium="user.is_premium"
              class="mt-6"
            />
            <div
              v-if="user.is_premium"
              style="border: 4px solid #FFF; bottom: 20px; right: 60%; width: 30px; height: 30px; background: #DEB580; position: absolute !important;"
              class="rounded-circle d-flex justify-center align-center"
            >
              <p class="pa-0 ma-0" style="font-weight: 600; font-size: 0.5em; color: #FFF;">{{ nivel }}</p>
            </div>
          </div>
        </v-list-item>
      </v-list-item-content>
    </v-list-item>
    <div
      class="ml-15 pl-5 mr-auto name"
      :class="$vuetify.breakpoint.height<'650' ? $vuetify.breakpoint.height<'590' ? 'mb-1': 'mb-5': 'mb-11'"
    >
      <p class="general__text--title ma-0">Hola,</p>
      <p class="general__text--title wrapper" style="white-space: pre-wrap;">
        Dr. {{ userName }}
      </p>
    </div>
    <v-list
      dense
      nav
      class="pa-0 drawer"
    >
      <the-drawer-item
        redirectToName="Inicio"
        iconName="mdi-home"
        itemName="Inicio"
        :isActive="checkRoute('Inicio')"
      />
      <the-drawer-item
        redirectToName="UnitsAreas"
        iconName="mdi-laptop"
        itemName="Unidades"
        :isActive="checkRoute('UnitsAreas') || checkRoute('UnitsAreasSpecialities') || checkRoute('UnitsAreasSpecialitiesTopics')"
      />
      <the-drawer-item
        redirectToName="Mazos"
        iconName="mdi-pencil-box-multiple"
        itemName="Mis flashcards"
        :isActive="checkRoute('Mazos') || checkRoute('CreateMazo') || checkRoute('UpdateMazo') || checkRoute('FlashcardFavouriteSimulator')"
      />
     <the-drawer-item
       redirectToName="Advances"
       iconName="mdi-finance"
       itemName="Avances"
       :isActive="checkRoute('Advances')"
     />
     <the-drawer-item
       redirectToName="Ranking"
       iconName="mdi-medal"
       itemName="Ranking"
       :isActive="checkRoute('Ranking')"
       :isNew="true"
     />

      <v-divider></v-divider>
      <the-drawer-item
        redirectToName="Profile"
        iconName="mdi-account"
        itemName="Perfil"
        :isActive="checkRoute('Profile')"
      />
      <!-- <the-drawer-item
        redirectToName=""
        iconName="mdi-close"
        itemName="Cerrar sesión"
        :isActive="checkRoute('')"
        @click="doLogout"
      /> -->
      <v-list-item
        link
        class="justify-center item"
        @mouseover="hover5 = false"
        @mouseleave="hover5 = false"
        @click="doLogout"
      >
        <div v-if="hover5" class="hover-item"></div>
        <div class="d-flex option">
          <v-list-item-icon class="">
            <v-icon>mdi-close</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item-content>
        </div>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
import showDrawer from '@/mixins/showDrawer'
import TheDrawerItem from '@/components/TheDrawerItem'
import TheAvatarImage from '@/components/TheAvatarImage'
import vuetifyHelpers from '@/mixins/vuetifyHelpers'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'Drawer',
  mixins: [showDrawer, vuetifyHelpers],
  components: {
    TheDrawerItem,
    TheAvatarImage
  },
  data () {
    return {
      hover5: false
    }
  },
  methods: {
    ...mapActions(['logout']),
    ...mapActions('profile', ['fetchUser']),
    doLogout () {
      this.logout()
      this.$router.push({ name: 'Login' })
      this.showDrawer = false
    },
    checkRoute (name) {
      if (this.$route.name === name) {
        return true
      } else {
        return false
      }
    }
  },
  computed: {
    ...mapState('profile', ['user']),
    ...mapGetters('profile', ['userName', 'profilePhoto', 'getExperience', 'getLevel']),
    nivel () {
      return this.getLevel
    }
  },
  async mounted () {
    await this.fetchUser()
  }
}
</script>

<style scoped>
.hover-item {
  position: absolute;
  width: 12px;
  height: 50px !important;
  left: 0;
  background: #1FC1D0;
  border-radius: 0px 2px 2px 0px;
}
.item {
  height: 50px !important;
}
.option {
  position: absolute;
  width: 60%;
}
.name {
  width: 245px;
  height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.wrapper {
  max-width:300px;
  -webkit-line-clamp: 1;
 -webkit-box-orient: vertical;
  overflow:hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.drawer {
  height: calc(100vh - (342px)) !important;
  overflow-y: scroll;
  padding-right: 20px;
}
@media (max-height: 650px) {
  .drawer {
    height: calc(100vh - (330px)) !important;
    margin-right: 5px;
  }
}
.drawer::-webkit-scrollbar {
    width: 2px !important;
}
.drawer::-webkit-scrollbar-track {
    background-color: #FFFFFF!important;
    border-radius: 10px;
}
.drawer::-webkit-scrollbar-thumb {
    background: #C4C4C4 !important;
}
</style>
